const {scriptsPath} = (window as Window).galen.cc.vco;

// the public path to our scripts on the server. This is necessary for webpack to know where to send requests for our dynamically imported code bundles.
// TODO: work out if there's a better way to tell webpack where to fetch dynamic bundles from. -ER
// @ts-ignore
__webpack_public_path__ = `${scriptsPath}/`;

import(/* webpackChunkName: "app" */ "./components/app")
  .then(() => {
    // side effects take care of the rest
  })
  .catch(ex => {
    // TODO handle errors fetching the bundle

    // eslint-disable-next-line no-console
    console.error(ex);
  });
