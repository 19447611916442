import {SeverityLevel, IExceptionTelemetry} from "@microsoft/applicationinsights-web";
import {vcoAppInsights} from "./app-insights/galen-app-insights";

enum LogEntryLevel {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  VERBOSE = "verbose",
}

class LogEntry {
  constructor(message: string, level: LogEntryLevel, error: Error | null) {
    const stack = error?.stack ?? null;
    this.message = message;
    this.level = level;
    this.name = !!error ? error.name || null : null;
    // This should be `typeof stack === "object"; leaving as-is for now since it seems to be working? Maybe? ¯\_(ツ)_/¯
    this.stack = stack === "object" ? JSON.stringify(stack) : stack;
    this.timeStamp = new Date();

    // check to see if err is xmlHTTPRequest
    // if (error.readyState) {
    //     this.number = error.status || null;
    //     this.type = error.statusText || null;
    //     this.message = error.responseText || null;
    //     this.responseType = (typeof error.responseType === "string") ? error.response : null;
    //     this.url = error.responseURL || null; // URL not Url to support browser error object shape
    // }
  }

  message: string;
  level: LogEntryLevel;
  name: string | null;
  stack: string | null;
  timeStamp: Date;
}

/**
 * @name _log
 * @param {LogEntry} logEntry - a LogEntry instance
 * @returns {void}
 */
function _log(logEntry: LogEntry) {
  // Throw this into a different event loop to prevent blocking
  setTimeout(() => {
    const stack = !!logEntry.stack ? `\r\n\r\n${logEntry.stack}` : "";
    const consoleMessage = `${logEntry.message}${stack}`;
    switch (logEntry.level) {
      case LogEntryLevel.INFO:
        console.info(consoleMessage); // eslint-disable-line no-console
        break;
      case LogEntryLevel.WARNING:
        console.warn(consoleMessage); // eslint-disable-line no-console
        break;
      case LogEntryLevel.VERBOSE:
        console.debug(consoleMessage); // eslint-disable-line no-console
        break;
      case LogEntryLevel.ERROR:
      default:
        console.error(consoleMessage); // eslint-disable-line no-console
    }
  }, 0);
}

/**
 * @name _sendError
 * @param {Error} error - original error object, if applicable
 * @param {object} properties - simple object to be passed to appInsights
 * @returns {void}
 */
function _sendError(error: Error, properties?: object) {
  // Throw this into a different event loop to prevent blocking
  setTimeout(() => {
    if (!!error) {
      const telemetry: IExceptionTelemetry = {
        error,
        severityLevel: SeverityLevel.Error,
        properties,
      };
      vcoAppInsights.trackException(telemetry);
    }
  }, 1);
}

const galenLogger = {
  logError: (error: Error | string, contextProperties?: object) => {
    const err = typeof error === "string" ? new Error(error) : error;
    _log(new LogEntry(err.message, LogEntryLevel.ERROR, err));
    _sendError(err, contextProperties);
  },
  logWarning: (message: string) => {
    _log(new LogEntry(message, LogEntryLevel.WARNING, null));
  },
  logInfo: (message: string) => {
    _log(new LogEntry(message, LogEntryLevel.INFO, null));
  },
  sendError: (error: Error, contextProperties?: object) => {
    _sendError(error, contextProperties);
  },
  logVerbose: (message: string) => {
    _log(new LogEntry(message, LogEntryLevel.VERBOSE, null));
  },
};

// TODO: fix this linting issue -ER
// eslint-disable-next-line consistent-return
window.addEventListener("error", event => {
  const {error} = event;

  // XXX Ignore errors that will be processed by componentDidCatch.
  // SEE: https://github.com/facebook/react/issues/10474
  if (error.stack && error.stack.indexOf("invokeGuardedCallbackDev") >= 0) {
    return true;
  }

  const contextProperties = {
    url: window.location.href,
  };

  galenLogger.sendError(error, contextProperties);
});

// window.onerror = function (event: Event | string, source?: string, fileno?: number, columnNumber?: number, error?: Error): ErrorEventHandler {
//
//     return;
// };

export {galenLogger};
